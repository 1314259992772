@import '../../../theme/common/variable';

.change-dot-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .change-dot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }
}

.sticky-table table tbody tr:hover td {
  background-color: $bg-gray-color;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
