@import '../../../theme/common/variable';

.blockui {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 88888;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.blockui-wrapper {
  width: 120px;
  padding: 0.5rem 1rem;
  background-color: $bg-yellow-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockui-spinner {
  width: 60px !important;
  height: 60px !important;
}
