$main-color: #ff8000 !important;
$secondary-color: #ff8000 !important;

$line-color: #dee2e6 !important;
$border-color: #ced4da !important;

$bg-main-color: #ffe6cc !important;
$bg-yellow-color: #fff2cc !important;
$bg-red-color: #f8cecc !important;
$bg-blue-color: #dae8fc !important;
$bg-gray-color: #f5f5f5 !important;
$bg-green-color: #d5e8d4 !important;
$bg-violet-color: #e1d5e7 !important;
$bg-light-yellow-color: #fae5c2 !important;
$bg-solid-yellow-color: #ede2c3 !important;
$bg-solid-gray-color: #c6c6c6 !important;
$bg-light-gray-color: #f0f0f0 !important;
$bg-white-color: #ffffff !important;
