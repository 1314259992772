.react-datepicker {
  &__navigation-icon {
    top: -5px;
    font-size: inherit;

    &::before {
      border-width: 2px 2px 0 0;
      border-color: #aaa;
    }
  }

  &__navigation:hover *::before {
    border-color: $main-color;
  }
}
