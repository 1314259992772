.anra-checkbox {
  &.form-check {
    padding-left: 2rem;

    .form-check-input {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: -2em;
      cursor: pointer;
    }

    .form-check-label {
      line-height: 2.125rem;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
