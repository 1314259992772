@import "../../../theme/common/variable";

// .react-datepicker-popper {
//      z-index: 1050; /* Adjust as needed */
// }

.fa-chevron-icon {
     cursor: pointer;
     color: gray;
     &:hover {
          color: $main-color;
     }
}

.table-component .div-table {
     display: block;
     overflow-y: auto;
     width: 100%;
     border: 0px;
     background-color: #9f9f9f;
     .div-tr:last-child .div-td {
          border-bottom: 1px solid $border-color;
     }
     .div-tr .div-td:last-child {
          border-right: 1px solid $border-color;
     }
     .div-table .div-tr:hover .div-td {
          background-color: $bg-gray-color;
     }
}

.table-component .div-table {
     height: calc(100vh - 480px - 4.5rem);

     @media screen and (max-height: 900px) {
          height: calc(100vh - 440px - 4.5rem);
     }

     @media screen and (max-height: 800px) {
          height: calc(100vh - 460px - 4.5rem);
     }

     @media screen and (max-height: 700px) {
          height: calc(100vh - 360px - 4.5rem);
     }

     @media screen and (max-height: 600px) {
          height: calc(100vh - 320px - 4.5rem);
     }

     @media screen and (max-height: 500px) {
          height: calc(100vh - 280px - 4.5rem);
     }
}

.table-component .div-table .div-tr .div-th {
     padding: 0.5rem 0.5rem !important;
}
.table-component .div-table .div-tr .div-td {
     padding: 0.2rem 0.2rem !important;
}

.sticky-table.parts-configuration-table .div-table .div-tr .div-td {
     background-color: $bg-white-color;
     padding: 0.1rem 0.1rem !important;
}

.sticky-table.parts-configuration-table .div-table .div-tr:hover .div-td {
     background-color: $bg-light-gray-color;
}

.sticky-table.parts-configuration-table .div-table .div-tr-selected .div-td {
     background-color: $bg-light-yellow-color;
}

.sticky-table.parts-configuration-table .div-table .div-tr-selected:hover .div-td {
     background-color: $bg-solid-yellow-color;
}

.table-component .div-table .div-tr-selected .div-td {
     background-color: $bg-light-gray-color;
}

.table-component .div-table .div-tr-selected:hover .div-td {
     background-color: $bg-light-gray-color;
}
