.page-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.page-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.page-main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: calc(100vh - 5.83333rem); // 70px is height of StatusHeader, 36px is height of footer
}

.page-sidebar {
  width: 272px;
  flex-basis: 272px;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 1.5rem 1rem 3rem;
  border-right: 1px solid $line-color;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-content {
  flex: 1;
  padding: 1rem 1rem;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.fit-content {
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  height: fit-content;
}
