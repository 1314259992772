.menus {
  padding-left: 0;
  .menus-dnd-container {
    &:focus-visible {
      outline: none;
    }
    p {
      max-width: none;
      font-weight: bold;
      margin: 0;
    }
  }
  &:hover {
    background: '#efefef';
  }
}

.chart_text {
  color: black;
}
