@import './theme/common/variable';

/* The following block can be included in a custom.scss */

/* make the customizations */
// $blue: #36489a !default;
// $gray-100: #f2f2f2 !default;
$primary: #ff8000;
$secondary: #ffff66;

/* import bootstrap to set changes */
@import './theme/common/common';
@import './theme/common/input';
@import '~bootstrap/scss/bootstrap';
@import './theme/common/stickyTable';
@import './theme/common/layout';
@import './theme/common/background';
@import './theme/common/button';
@import './theme/common/reactDatePicker';

html {
  font-size: 12px;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

/** custom Accordion **/
.accordion-button {
  position: relative;
  background-color: var(--bs-accordion-active-bg);
  padding-left: 2.75rem;

  &::before {
    content: '';
    position: absolute;
    top: 1.0625rem;
    left: 1.25rem;
    width: 1rem;
    height: 1rem;
    background-color: $main-color;
    border-radius: 50%;
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-collapse + .accordion-summary {
  display: block;
  opacity: 1;
}

.accordion-collapse.show + .accordion-summary,
.accordion-collapse.collapsing + .accordion-summary {
  display: none;
  opacity: 0;
  transition: all 0.3s ease 0;
}
/** end custom Accordion **/

/** fix collapse **/
.collapsing {
  transition: unset !important;
}
