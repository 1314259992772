.btn {
  min-width: 6rem;
  &-primary {
    color: #fff;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.btn {
  &--auto {
    min-width: auto;
  }

  &--green {
    color: #000;
    background-color: rgb(213, 232, 212);
    border-color: #d5e8d4;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: rgb(177, 196, 176);
      border-color: rgb(177, 196, 176);
    }
  }

  &--violet {
    color: #000;
    background-color: rgb(225, 213, 231);
    border-color: rgb(225, 213, 231);

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: rgb(189, 177, 195);
      border-color: rgb(189, 177, 195);
    }
  }

  &--yellow {
    color: #000;
    background-color: #ffff66;
    border-color: #ffff66;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: #dbdb42;
      border-color: #dbdb42;
    }
  }

  &--red {
    color: #000;
    background-color: #f8cecc;
    border-color: #f8cecc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: #d4aaa8;
      border-color: #d4aaa8;
    }
  }

  &--white {
    color: #000;
    background-color: #fff;
    border-color: #fff;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: #ededed;
      border-color: #ededed;
    }
  }

  &--blue {
    color: #000;
    background-color: #dae8fc;
    border-color: #dae8fc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: #c8d6ea;
      border-color: #c8d6ea;
    }
  }

  &--orange {
    color: #000;
    background-color: #ffe6cc;
    border-color: #ffe6cc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover,
    &:focus {
      color: #000;
      background-color: #dbc2a8;
      border-color: #dbc2a8;
    }
  }
}
