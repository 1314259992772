@import "../../../theme/common/variable";

.purchase-detail-table .div-tr {
  .div-td {
    border-bottom: 1px solid $border-color;
    padding: 0.5rem 0.5rem !important;
  }
  .div-td:last-child {
    border-right: 1px solid $border-color;
  }
  .div-th:last-child {
    border-right: 1px solid $border-color;
  }
}
.purchase-detail-table {
  border-right: 0px !important;
}

.background-red {
  background-color: $bg-red-color;
}

.page-content .sticky-table {
  flex: 1;
  width: 100% !important;
}

.sticky-table .div-table.table-status-new .div-tr .div-td {
  background-color: $bg-yellow-color;
  padding: 0rem 0rem !important;
}

.sticky-table .div-table.table-status-new .div-tr:hover .div-td {
  background-color: $bg-solid-yellow-color;
}

.sticky-table .div-table.table-status-new .div-tr-selected .div-td {
  background-color: $bg-light-yellow-color;
}

.sticky-table .div-table.table-status-new .div-tr-selected:hover .div-td {
  background-color: $bg-solid-yellow-color;
}

.sticky-table .div-table.table-approved .div-tr .div-td {
  background-color: $bg-white-color;
  padding: 0rem 0rem !important;
}

.sticky-table .div-table.table-approved .div-tr:hover .div-td {
  background-color: $bg-light-gray-color;
}

.sticky-table .div-table.table-approved .div-tr-selected .div-td {
  background-color: $bg-light-yellow-color;
}

.sticky-table .div-table.table-approved .div-tr-selected:hover .div-td {
  background-color: $bg-solid-yellow-color;
}
