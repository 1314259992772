@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import "../../../theme/common/variable";

.container-login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-login {
  height: 100%;
  width: 450px;
  background-color: #fff;
  position: relative;
  border-radius: 0.5rem;
  padding: 2rem;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.text p {
  font-size: 13px;
  color: #bac4d4;
  font-weight: 700;
}

.text h3,
.text h5 {
  color: $main-color;
  font-weight: bold;
}

.input-text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.fa-eye {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}

.warning {
  border: 1px solid red !important;
}

.type_password {
  border: 1px solid red !important;
}

.error {
  color: red;
  text-align: center;
}

.roles {
  padding: 20px;
}

.dialog-job {
  width: 480px !important;
  max-width: 480px !important;
}
