.sticky-table {
  overflow: auto;
  height: fit-content;
  border-bottom: 1px solid $table-border-color;
  background-color: #999;
  position: relative;
  z-index: 10;

  table {
    min-width: fit-content;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-left: 1px solid $table-border-color;
  }

  tbody tr:last-child,
  tbody tr.divide.last-row {
    td {
      border-bottom: none;
    }
  }

  thead th {
    position: sticky;
    top: 0px;
  }

  th {
    background-color: $bg-main-color;
    border-top: 1px solid $table-border-color;
    text-align: center;
    color: #333;

    &.sort {
      cursor: pointer;
    }

    &[colspan] {
      border-bottom: none;
    }
  }

  th,
  td {
    border-bottom: 1px solid $table-border-color;
    border-right: 1px solid $table-border-color;
    white-space: nowrap;
    vertical-align: middle;
  }

  td.wrap {
    white-space: pre-wrap;
  }

  tr.divide {
    td {
      border-top: 2px solid #333;
    }

    &:first-child {
      td {
        border-top: 0;
      }
    }
  }
}

/*** div-table ***/
.div-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid $table-border-color;
  min-width: fit-content;
  position: relative;
  z-index: 10;

  &.fixsize-column {
    .div-td,
    .div-th {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.div-tr {
  display: flex;
  align-items: stretch;

  .div-td {
    background-color: #fff;
  }

  &:hover .div-td {
    background-color: #f5f5f5;
  }
}

.div-thead {
  font-weight: bold;
  position: sticky;
  top: 0px;
}

.div-td,
.div-th {
  flex: 1 1 auto;
  border-left: 1px solid $table-border-color;
  border-top: 1px solid $table-border-color;
  padding: 0.3rem 0.3rem;
}

.div-th {
  background-color: $bg-main-color;
  text-align: center;
}

// .div-tbody {
//   width: 100%;

//   .div-tr:last-child {
//     border-bottom: 1px solid $table-border-color;
//   }
// }
/*** end div-table ***/
