/*** react datepicker custom ***/
.react-datepicker-popper {
  z-index: 99 !important;
}

.text-single-row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
  margin-left: 0.5rem;
}
