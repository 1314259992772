@import '../../../theme/common/variable';

.status-header {
  background-color: $main-color;
  color: #fff;
  padding: 0.75rem 0 0;

  &__value {
    color: yellow;
  }
}

.main-menu {
  /* common setting */
  ul {
    list-style: none;
    display: inline-flex;
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    position: relative;

    &:hover {
      background-color: $bg-main-color;
      color: #000;
    }

    span,
    a {
      padding: 0.5rem 1rem;
      display: block;
      cursor: pointer;
      letter-spacing: 0.5pt;
      font-weight: 500;
    }
  }

  /* level 1 */
  & > ul {
    & > li {
      span,
      a {
        &.active {
          background-color: $bg-main-color;
          color: $main-color;
          font-weight: bold;
        }
      }

      &:hover > ul {
        display: block;
      }
    }
  }

  /* level 2 */
  & > ul ul {
    position: absolute;
    top: 30px;
    left: 0;
    display: none;
    z-index: 99999;
    min-width: 220px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);

    li {
      span,
      a {
        color: #000;
        text-decoration: none;

        &.active {
          background-color: $main-color;
          color: #fff !important;

          &:hover {
            background-color: $main-color;
            color: #fff !important;
          }
        }

        &:hover {
          background-color: $bg-main-color;
        }
      }
    }
  }
}
